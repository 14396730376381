var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { call, put, takeEvery } from "redux-saga/effects";
import { CONFIG } from "../../config/app.config";
import logger from "../../logger";
import * as leadActions from "./lead.actions";
import * as leadApi from "./lead.api";
function getLeadStartFlow(action) {
    var payload, leadId, publicId, lead, unknownError_1, error;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                payload = action.payload;
                leadId = payload.leadId, publicId = payload.publicId;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(leadApi.getLead, leadId, publicId)];
            case 2:
                lead = (_a.sent()).data;
                return [4 /*yield*/, put(leadActions.getLead.actions.success({
                        leadId: leadId,
                        lead: lead,
                    }))];
            case 3:
                _a.sent();
                return [3 /*break*/, 6];
            case 4:
                unknownError_1 = _a.sent();
                error = unknownError_1;
                logger.logError({ error: error });
                return [4 /*yield*/, put(leadActions.getLead.actions.failure({ leadId: leadId, error: error }))];
            case 5:
                _a.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function updateLeadStartFlow(action) {
    var payload, leadId, updatedLead, publicId, lead, unknownError_2, error;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                payload = action.payload;
                leadId = payload.leadId, updatedLead = payload.updatedLead, publicId = payload.publicId;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(leadApi.updateLead, leadId, updatedLead, publicId)];
            case 2:
                lead = (_a.sent()).data;
                return [4 /*yield*/, put(leadActions.updateLead.actions.success({
                        leadId: leadId,
                        lead: lead,
                    }))];
            case 3:
                _a.sent();
                return [3 /*break*/, 6];
            case 4:
                unknownError_2 = _a.sent();
                error = unknownError_2;
                logger.logError({ error: error });
                return [4 /*yield*/, put(leadActions.updateLead.actions.failure({ leadId: leadId, error: error }))];
            case 5:
                _a.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function getLeadsPagedStartFlow(action) {
    var payload, identifier, _a, page, _b, limit, filterData, skip, data, unknownError_3, error;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                payload = action.payload;
                identifier = payload.identifier, _a = payload.page, page = _a === void 0 ? 1 : _a, _b = payload.limit, limit = _b === void 0 ? CONFIG.DEFAULT_FETCH_LIMIT : _b, filterData = payload.filterData;
                _c.label = 1;
            case 1:
                _c.trys.push([1, 4, , 6]);
                skip = page * limit;
                return [4 /*yield*/, call(leadApi.getLeads, skip, limit, filterData)];
            case 2:
                data = (_c.sent()).data;
                return [4 /*yield*/, put(leadActions.getLeadsPaged.actions.success({
                        identifier: identifier,
                        page: page,
                        leads: data.items,
                        count: data.meta.totalItems,
                    }))];
            case 3:
                _c.sent();
                return [3 /*break*/, 6];
            case 4:
                unknownError_3 = _c.sent();
                error = unknownError_3;
                logger.logError({ error: error });
                return [4 /*yield*/, put(leadActions.getLeadsPaged.actions.failure({
                        error: error,
                        identifier: identifier,
                        page: page,
                    }))];
            case 5:
                _c.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function saga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(leadActions.getLead.types.START, getLeadStartFlow)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeEvery(leadActions.updateLead.types.START, updateLeadStartFlow)];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeEvery(leadActions.getLeadsPaged.types.START, getLeadsPagedStartFlow)];
            case 3:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
// eslint-disable-next-line import/no-default-export
export default saga;
