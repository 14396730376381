import addBusinessDaysBase from "date-fns/addBusinessDays";
import addDaysBase from "date-fns/addDays";
import addHoursBase from "date-fns/addHours";
import addMonthsBase from "date-fns/addMonths";
import addQuartersBase from "date-fns/addQuarters";
import addWeeksBase from "date-fns/addWeeks";
import addYearsBase from "date-fns/addYears";
import compareAscBase from "date-fns/compareAsc";
import differenceInBusinessDaysBase from "date-fns/differenceInBusinessDays";
import differenceInCalendarDaysBase from "date-fns/differenceInCalendarDays";
import differenceInCalendarMonthsBase from "date-fns/differenceInCalendarMonths";
import differenceInDaysBase from "date-fns/differenceInDays";
import differenceInHoursBase from "date-fns/differenceInHours";
import differenceInMinutesBase from "date-fns/differenceInMinutes";
import differenceInMonthsBase from "date-fns/differenceInMonths";
import differenceInWeeksBase from "date-fns/differenceInWeeks";
import differenceInYearsBase from "date-fns/differenceInYears";
import eachDayOfIntervalBase from "date-fns/eachDayOfInterval";
import endOfDayBase from "date-fns/endOfDay";
import endOfMonthBase from "date-fns/endOfMonth";
import endOfQuarterBase from "date-fns/endOfQuarter";
import endOfWeekBase from "date-fns/endOfWeek";
import endOfYearBase from "date-fns/endOfYear";
import formatBase from "date-fns/format";
import fromUnixTimeBase from "date-fns/fromUnixTime";
import getDayBase from "date-fns/getDate";
import getHourBase from "date-fns/getHours";
import getMinuteBase from "date-fns/getMinutes";
import getMonthBase from "date-fns/getMonth";
import getQuarterBase from "date-fns/getQuarter";
import getYearBase from "date-fns/getYear";
import isAfterBase from "date-fns/isAfter";
import isBeforeBase from "date-fns/isBefore";
import isEqualBase from "date-fns/isEqual";
import isFutureBase from "date-fns/isFuture";
import isLeapYearBase from "date-fns/isLeapYear";
import isSameDayBase from "date-fns/isSameDay";
import isSameMonthBase from "date-fns/isSameMonth";
import isTodayBase from "date-fns/isToday";
import isWeekendBase from "date-fns/isWeekend";
import isYesterdayBase from "date-fns/isYesterday";
import lastDayOfMonthBase from "date-fns/lastDayOfMonth";
import maxBase from "date-fns/max";
import minBase from "date-fns/min";
import parseISOBase from "date-fns/parseISO";
import setDayBase from "date-fns/setDate";
import setHoursBase from "date-fns/setHours";
import setMinutesBase from "date-fns/setMinutes";
import setMonthBase from "date-fns/setMonth";
import setQuarterBase from "date-fns/setQuarter";
import setSecondsBase from "date-fns/setSeconds";
import setYearBase from "date-fns/setYear";
import startOfDayBase from "date-fns/startOfDay";
import startOfMonthBase from "date-fns/startOfMonth";
import startOfQuarterBase from "date-fns/startOfQuarter";
import startOfTodayBase from "date-fns/startOfToday";
import startOfWeekBase from "date-fns/startOfWeek";
import startOfYearBase from "date-fns/startOfYear";
import subBusinessDaysBase from "date-fns/subBusinessDays";
import subDaysBase from "date-fns/subDays";
import subHoursBase from "date-fns/subHours";
import subMonthsBase from "date-fns/subMonths";
import subQuartersBase from "date-fns/subQuarters";
import subWeeksBase from "date-fns/subWeeks";
import subYearsBase from "date-fns/subYears";
export var normalizeDate = function (date) {
    if (typeof date === "number") {
        return new Date(date);
    }
    if (typeof date === "string") {
        return parseISOBase(date);
    }
    return date;
};
export var addBusinessDays = function (date, amount) {
    return addBusinessDaysBase(normalizeDate(date), amount);
};
export var addDays = function (date, amount) {
    return addDaysBase(normalizeDate(date), amount);
};
export var addHours = function (date, amount) {
    return addHoursBase(normalizeDate(date), amount);
};
export var addMonths = function (date, amount) {
    return addMonthsBase(normalizeDate(date), amount);
};
export var addQuarters = function (date, amount) {
    return addQuartersBase(normalizeDate(date), amount);
};
export var addWeeks = function (date, amount) {
    return addWeeksBase(normalizeDate(date), amount);
};
export var addYears = function (date, amount) {
    return addYearsBase(normalizeDate(date), amount);
};
export var compareAsc = function (dateLeft, dateRight) {
    return compareAscBase(normalizeDate(dateLeft), normalizeDate(dateRight));
};
/**
 * @info Count does not include last day
 */
export var differenceInBusinessDays = function (dateLeft, dateRight) {
    return differenceInBusinessDaysBase(normalizeDate(dateLeft), normalizeDate(dateRight));
};
/**
 * @info Count does not include last day
 */
export var differenceInCalendarDays = function (dateLeft, dateRight) {
    return differenceInCalendarDaysBase(normalizeDate(dateLeft), normalizeDate(dateRight));
};
export var differenceInCalendarMonths = function (dateLeft, dateRight) {
    return differenceInCalendarMonthsBase(normalizeDate(dateLeft), normalizeDate(dateRight));
};
export var differenceInDays = function (dateLeft, dateRight) {
    return differenceInDaysBase(normalizeDate(dateLeft), normalizeDate(dateRight));
};
export var differenceInHours = function (dateLeft, dateRight) {
    return differenceInHoursBase(normalizeDate(dateLeft), normalizeDate(dateRight));
};
export var differenceInMinutes = function (dateLeft, dateRight) {
    return differenceInMinutesBase(normalizeDate(dateLeft), normalizeDate(dateRight));
};
export var differenceInMonths = function (dateLeft, dateRight) {
    return differenceInMonthsBase(normalizeDate(dateLeft), normalizeDate(dateRight));
};
export var differenceInWeeks = function (dateLeft, dateRight) {
    return differenceInWeeksBase(normalizeDate(dateLeft), normalizeDate(dateRight));
};
export var differenceInYears = function (dateLeft, dateRight) {
    return differenceInYearsBase(normalizeDate(dateLeft), normalizeDate(dateRight));
};
export var eachDayOfInterval = function (interval, options) {
    return eachDayOfIntervalBase({
        start: normalizeDate(interval.start),
        end: normalizeDate(interval.end),
    }, options);
};
export var endOfDay = function (date) { return endOfDayBase(normalizeDate(date)); };
export var endOfMonth = function (date) { return endOfMonthBase(normalizeDate(date)); };
export var endOfQuarter = function (date) {
    return endOfQuarterBase(normalizeDate(date));
};
export var endOfWeek = function (date, options) { return endOfWeekBase(normalizeDate(date), options); };
export var endOfYear = function (date) { return endOfYearBase(normalizeDate(date)); };
export var format = function (date, format, options) {
    return formatBase(normalizeDate(date), format, options);
};
export var fromUnixTime = function (timestamp) { return fromUnixTimeBase(timestamp); };
/**
 * @info Returns day of month
 */
export var getDay = function (date) { return getDayBase(normalizeDate(date)); };
export var getHour = function (date) { return getHourBase(normalizeDate(date)); };
export var getMinute = function (date) { return getMinuteBase(normalizeDate(date)); };
export var getMonth = function (date) { return getMonthBase(normalizeDate(date)); };
export var getQuarter = function (date) { return getQuarterBase(normalizeDate(date)); };
export var getYear = function (date) { return getYearBase(normalizeDate(date)); };
export var isAfter = function (date, dateToCompare) {
    return isAfterBase(normalizeDate(date), normalizeDate(dateToCompare));
};
export var isBefore = function (date, dateToCompare) {
    return isBeforeBase(normalizeDate(date), normalizeDate(dateToCompare));
};
export var isEqual = function (dateLeft, dateRight) {
    return isEqualBase(normalizeDate(dateLeft), normalizeDate(dateRight));
};
export var isFuture = function (date) { return isFutureBase(normalizeDate(date)); };
export var isLeapYear = function (date) { return isLeapYearBase(normalizeDate(date)); };
export var isSameDay = function (dateLeft, dateRight) {
    return isSameDayBase(normalizeDate(dateLeft), normalizeDate(dateRight));
};
export var isSameMonth = function (dateLeft, dateRight) {
    return isSameMonthBase(normalizeDate(dateLeft), normalizeDate(dateRight));
};
export var isToday = function (date) { return isTodayBase(normalizeDate(date)); };
export var isWeekend = function (date) { return isWeekendBase(normalizeDate(date)); };
export var isYesterday = function (date) {
    return isYesterdayBase(normalizeDate(date));
};
export var lastDayOfMonth = function (date) {
    return lastDayOfMonthBase(normalizeDate(date));
};
export var max = function (datesArray) {
    return maxBase(datesArray.map(function (date) { return normalizeDate(date); }));
};
export var min = function (datesArray) {
    return minBase(datesArray.map(function (date) { return normalizeDate(date); }));
};
export var parseISO = function (date) { return parseISOBase(date); };
export var setDay = function (date, dayOfMonth) {
    return setDayBase(normalizeDate(date), dayOfMonth);
};
export var setHours = function (date, hours) {
    return setHoursBase(normalizeDate(date), hours);
};
export var setMinutes = function (date, hours) {
    return setMinutesBase(normalizeDate(date), hours);
};
export var setQuarter = function (date, amount) {
    return setQuarterBase(normalizeDate(date), amount);
};
export var setSeconds = function (date, hours) {
    return setSecondsBase(normalizeDate(date), hours);
};
export var setMonth = function (date, month) {
    return setMonthBase(normalizeDate(date), month);
};
export var setYear = function (date, amount) {
    return setYearBase(normalizeDate(date), amount);
};
export var startOfDay = function (date) { return startOfDayBase(normalizeDate(date)); };
export var startOfMonth = function (date) {
    return startOfMonthBase(normalizeDate(date));
};
export var startOfQuarter = function (date) {
    return startOfQuarterBase(normalizeDate(date));
};
export var startOfToday = function () { return startOfTodayBase(); };
export var startOfWeek = function (date, options) { return startOfWeekBase(normalizeDate(date), options); };
export var startOfYear = function (date) {
    return startOfYearBase(normalizeDate(date));
};
export var subBusinessDays = function (date, amount) {
    return subBusinessDaysBase(normalizeDate(date), amount);
};
export var subDays = function (date, amount) {
    return subDaysBase(normalizeDate(date), amount);
};
export var subHours = function (date, amount) {
    return subHoursBase(normalizeDate(date), amount);
};
export var subMonths = function (date, amount) {
    return subMonthsBase(normalizeDate(date), amount);
};
export var subQuarters = function (date, amount) {
    return subQuartersBase(normalizeDate(date), amount);
};
export var subWeeks = function (date, amount) {
    return subWeeksBase(normalizeDate(date), amount);
};
export var subYears = function (date, amount) {
    return subYearsBase(normalizeDate(date), amount);
};
