var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Ajv from "ajv";
export var formatAddress = function (addressObject, showZip, showCityAndState, showState) {
    if (showZip === void 0) { showZip = true; }
    if (showCityAndState === void 0) { showCityAndState = true; }
    if (showState === void 0) { showState = true; }
    var box = addressObject.box, houseNumber = addressObject.number, city = addressObject.city, state = addressObject.state, street = addressObject.street, zip = addressObject.zip, _a = addressObject.country, country = _a === void 0 ? "" : _a;
    // Street info
    var streetInfoComponents = [];
    if (street)
        streetInfoComponents.push(street);
    if (street && houseNumber)
        streetInfoComponents.push(houseNumber);
    if (street && houseNumber && box)
        streetInfoComponents.push(box);
    var streetInfo = streetInfoComponents.join(" ");
    // City info
    var cityInfoComponent = [];
    if (showZip && zip)
        cityInfoComponent.push(zip);
    if (showCityAndState && city)
        cityInfoComponent.push(city);
    if (showCityAndState && showState && state && country !== "BE")
        cityInfoComponent.push(state);
    var cityInfo = cityInfoComponent.join(" ");
    // Return
    if (streetInfo.length && cityInfo.length) {
        return "".concat(streetInfo, ", ").concat(cityInfo);
    }
    if (streetInfo.length) {
        return streetInfo;
    }
    if (cityInfo.length) {
        return cityInfo;
    }
    return "";
};
export var isValidAddress = function (addressObject) {
    if (!addressObject) {
        return false;
    }
    return (addressObject.street &&
        addressObject.street.length > 0 &&
        addressObject.number &&
        addressObject.number.length > 0 &&
        addressObject.zip &&
        addressObject.zip.length > 0 &&
        addressObject.city &&
        addressObject.city.length > 0 &&
        addressObject.country &&
        addressObject.country.length > 0);
};
export var EAddressField;
(function (EAddressField) {
    EAddressField["Street"] = "street";
    EAddressField["Number"] = "number";
    EAddressField["Box"] = "box";
    EAddressField["Zip"] = "zip";
    EAddressField["City"] = "city";
    EAddressField["State"] = "state";
    EAddressField["Country"] = "country";
})(EAddressField || (EAddressField = {}));
var DEFAULT_MAX_LENGTH = 100;
export var getLengthLimits = function (country) {
    if (country === "BE") {
        return {
            street: { min: 1, max: DEFAULT_MAX_LENGTH },
            number: { min: 1, max: 5 },
            box: { min: 1, max: 5 },
            zip: { min: 4, max: 4 },
            city: { min: 1, max: DEFAULT_MAX_LENGTH },
        };
    }
    return {
        street: { min: 1, max: DEFAULT_MAX_LENGTH },
        number: { min: 1, max: DEFAULT_MAX_LENGTH },
        box: { min: 1, max: DEFAULT_MAX_LENGTH },
        zip: { min: 1, max: DEFAULT_MAX_LENGTH },
        city: { min: 1, max: DEFAULT_MAX_LENGTH },
        state: { min: 1, max: DEFAULT_MAX_LENGTH },
    };
};
var getCompleteAddressSchema = function (minMaxLengths) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    return {
        required: ["street", "number", "zip", "city", "country"],
        properties: {
            street: {
                type: "string",
                minLength: (_a = minMaxLengths.street) === null || _a === void 0 ? void 0 : _a.min,
                maxLength: (_b = minMaxLengths.street) === null || _b === void 0 ? void 0 : _b.max,
            },
            number: {
                type: "string",
                minLength: (_c = minMaxLengths.number) === null || _c === void 0 ? void 0 : _c.min,
                maxLength: (_d = minMaxLengths.number) === null || _d === void 0 ? void 0 : _d.max,
            },
            box: {
                type: ["string", "null"],
                minLength: (_e = minMaxLengths.box) === null || _e === void 0 ? void 0 : _e.min,
                maxLength: (_f = minMaxLengths.box) === null || _f === void 0 ? void 0 : _f.max,
            },
            zip: {
                type: "string",
                minLength: (_g = minMaxLengths.zip) === null || _g === void 0 ? void 0 : _g.min,
                maxLength: (_h = minMaxLengths.zip) === null || _h === void 0 ? void 0 : _h.max,
            },
            city: {
                type: "string",
                minLength: (_j = minMaxLengths.city) === null || _j === void 0 ? void 0 : _j.min,
                maxLength: (_k = minMaxLengths.city) === null || _k === void 0 ? void 0 : _k.max,
            },
            country: {
                type: "string",
            },
        },
    };
};
export var getAddressSchema = function (fields, minMax) {
    var properties = {};
    var schema = getCompleteAddressSchema(minMax);
    Object.keys(schema.properties)
        .filter(function (field) { return fields.includes(field); })
        .forEach(function (property) {
        // @ts-ignore
        properties[property] = schema.properties[property];
    });
    return {
        required: schema.required.filter(function (field) { return fields.includes(field); }),
        properties: properties,
    };
};
export var validateAddress = function (address, fields, minMax) {
    if (fields === void 0) { fields = ["street", "number", "box", "zip", "city", "country"]; }
    var addressSchema = getAddressSchema(fields, minMax);
    // Trim & replace empty strings with null for correct validation
    var cleanAddress = __assign({}, address);
    Object.keys(cleanAddress).forEach(function (key) {
        var _a;
        cleanAddress[key] = (_a = cleanAddress[key]) === null || _a === void 0 ? void 0 : _a.trim();
        if (cleanAddress[key] === "") {
            cleanAddress[key] = null;
        }
    });
    var ajv = new Ajv({ allErrors: true });
    var isValid = ajv.validate(addressSchema, cleanAddress);
    if (isValid) {
        return true;
    }
    else {
        return ajv.errors;
    }
};
export var getAddressFields = function (lookupType) {
    switch (lookupType) {
        case "(cities)":
            return ["city", "country"];
        default:
            return ["street", "number", "box", "zip", "city", "state", "country"];
    }
};
export var setAddressFieldsError = function (formData, errors, key) {
    if (!formData[EAddressField.City]) {
        errors[key].addError(EAddressField.City);
    }
    if (!formData[EAddressField.Number]) {
        errors[key].addError(EAddressField.Number);
    }
    if (!formData[EAddressField.Street]) {
        errors[key].addError(EAddressField.Street);
    }
    if (!formData[EAddressField.Zip]) {
        errors[key].addError(EAddressField.Zip);
    }
};
