var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { CONFIG } from "../../config/app.config";
import api from "../../utils/api/api.utils";
import { append } from "../../utils/api.utils";
export var getLead = function (leadId, publicId) {
    return api.get(append("/leads/".concat(leadId), { publicId: publicId }, false));
};
export var resendInvite = function (leadId) {
    return api.post("/leads/".concat(leadId, "/resend-invite"));
};
export var startLead = function (leadId, publicId) {
    return api.post(append("/leads/".concat(leadId, "/start-direct"), { publicId: publicId }, false));
};
export var updateLead = function (leadId, updatedLead, publicId) {
    return api.patch(append("/leads/".concat(leadId), { publicId: publicId }, false), {
        data: updatedLead,
    });
};
export var getLeads = function (skip, limit, filterData) {
    if (limit === void 0) { limit = CONFIG.DEFAULT_FETCH_LIMIT; }
    if (filterData === void 0) { filterData = {}; }
    return api.get(append("/leads", __assign({ page: Math.floor(skip / limit), limit: limit }, filterData), false));
};
export var getLeadActivities = function (leadId) {
    return api.get(append("/leads/".concat(leadId, "/activities"), { page: 1, limit: 100 }, false));
};
