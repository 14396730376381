// eslint-disable-next-line no-restricted-imports
import { enUS as en, fr, nl } from "date-fns/locale";
import isObject from "lodash/isObject";
import { ELocale } from "../types/i18n.types";
import { addDays, format, isToday, isYesterday, setHours, setMinutes, setSeconds, subDays, } from "./date-fns.utils";
import { getLocale, getLocalizedText } from "./i18n/i18n.utils";
var getFormatterLocale = function () {
    switch (getLocale()) {
        case ELocale.NL:
            return nl;
        case ELocale.FR:
            return fr;
        case ELocale.EN:
        default:
            return en;
    }
};
export var formatTime = function (date) {
    var pattern = "HH:mm";
    return format(date, pattern, { locale: getFormatterLocale() });
};
export var formatDate = function (date, pattern) {
    if (pattern === void 0) { pattern = "dd/MM/yyyy"; }
    return format(date, pattern, { locale: getFormatterLocale() });
};
export var formatDateAndTime = function (date, patternDate) {
    return "".concat(formatDate(date, patternDate), " ").concat(formatTime(date));
};
export var formatMediumDate = function (date) {
    var pattern = "d MMM yyyy";
    return format(date, pattern, { locale: getFormatterLocale() });
};
export var formatLongDate = function (date) {
    var pattern = "iii, d MMMM yyyy";
    return format(date, pattern, { locale: getFormatterLocale() });
};
export var formatDateWithCustomClosebyFormat = function (date, customFormat) {
    if (isToday(date)) {
        return getLocalizedText("system.today");
    }
    if (isYesterday(date)) {
        return getLocalizedText("system.yesterday");
    }
    return format(date, customFormat || "dd/MM/yyyy", {
        locale: getFormatterLocale(),
    });
};
export var mapTimeZonedDateToUtcIsoString = function (date) {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds())).toISOString();
};
export var mapObjectWithTimeZonedDatesToUtcIsoString = function (data, keepTime, skipMappingList) {
    if (keepTime === void 0) { keepTime = false; }
    if (Array.isArray(data)) {
        return data.map(function (value) {
            return mapObjectWithTimeZonedDatesToUtcIsoString(value, keepTime, skipMappingList);
        });
    }
    if (Object.prototype.toString.call(data) === "[object Date]") {
        if (keepTime) {
            return mapTimeZonedDateToUtcIsoString(data);
        }
        return mapTimeZonedDateToUtcIsoString(stripTime(data));
    }
    if (isObject(data)) {
        var result_1 = data;
        Object.keys(data).forEach(function (key) {
            if (result_1.hasOwnProperty(key)) {
                var isSkipped = (skipMappingList === null || skipMappingList === void 0 ? void 0 : skipMappingList.includes(key)) || false;
                if (isSkipped) {
                    return;
                }
                result_1[key] = mapObjectWithTimeZonedDatesToUtcIsoString(result_1[key], keepTime, skipMappingList);
            }
        });
        return result_1;
    }
    return data;
};
export var getKeyForMinutes = function (minutes) {
    if (minutes > -10 && minutes <= 0) {
        return getLocalizedText("date.just_now");
    }
    if (minutes > -45 && minutes < -10) {
        return getLocalizedText("date.less_then.hour");
    }
    return getLocalizedText("date.minutes".concat(minutes < 0 ? "_after" : "_before"), {
        minutes: "".concat(Math.abs(minutes)),
    }, Math.abs(minutes));
};
export var getKeyForHours = function (hours) {
    return getLocalizedText("date.hours".concat(hours < 0 ? "_after" : "_before"), {
        hours: "".concat(Math.abs(hours)),
    }, Math.abs(hours));
};
export var getKeyForDays = function (days) {
    if (days === -1) {
        return getLocalizedText("system.yesterday");
    }
    if (days === 1) {
        return getLocalizedText("system.tomorrow");
    }
    return getLocalizedText("date.days".concat(days < 0 ? "_after" : "_before"), {
        days: "".concat(Math.abs(days)),
    }, Math.abs(days));
};
export var getKeyForWeeks = function (weeks) {
    return getLocalizedText("date.weeks".concat(weeks < 0 ? "_after" : "_before"), {
        weeks: "".concat(Math.abs(weeks)),
    }, Math.abs(weeks));
};
export var getKeyForMonths = function (months) {
    return getLocalizedText("date.months".concat(months < 0 ? "_after" : "_before"), {
        months: "".concat(Math.abs(months)),
    }, Math.abs(months));
};
export var getTerm = function (contract, params) {
    var _a = params || {}, _b = _a.extended, extended = _b === void 0 ? false : _b, _c = _a.dateFormatter, dateFormatter = _c === void 0 ? formatMediumDate : _c;
    if (!contract) {
        return "";
    }
    var termComponents = [
        dateFormatter(contract.startDate),
        contract.stopDate
            ? dateFormatter(contract.stopDate)
            : getLocalizedText("contract.indefinite"),
    ].filter(Boolean);
    var term = termComponents.join(" - ");
    if (extended) {
        return getLocalizedText("contract.term_extended", { value: term });
    }
    return term;
};
export var stripTime = function (date) {
    return setSeconds(setMinutes(setHours(date, 0), 0), 0);
};
export var addWeekdays = function (date, daysToAdd) {
    var subtractDays = daysToAdd < 0;
    var result = date;
    var remainingWeekdaysCounter = Math.abs(daysToAdd);
    while (remainingWeekdaysCounter > 0) {
        result = subtractDays ? subDays(result, 1) : addDays(result, 1);
        var dayOfWeek = result.getDay();
        // If not weekend
        if (dayOfWeek !== 6 && dayOfWeek !== 0) {
            remainingWeekdaysCounter -= 1;
        }
    }
    return result;
};
export var twoWeeksLater = function () {
    var today = new Date();
    var twoWeeksLater = new Date(today);
    twoWeeksLater.setDate(today.getDate() + 14);
    return twoWeeksLater.toISOString();
};
