var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createAction, EActionGeneric, EActionState, } from "../utils/action.utils";
import { sanitizeQuery } from "../utils/api.utils";
var generateGetDetail = function (params) {
    var entity = params.entity;
    var getDetailStart = createAction({
        entity: entity,
        generic: EActionGeneric.Detail,
        state: EActionState.Start,
    });
    var getDetailStartNoThrottle = createAction({
        entity: entity,
        generic: EActionGeneric.Detail,
        state: EActionState.StartNoThrottle,
    });
    var getDetailSucceeded = createAction({
        entity: entity,
        generic: EActionGeneric.Detail,
        state: EActionState.Success,
    });
    var getDetailFailed = createAction({
        entity: entity,
        generic: EActionGeneric.Detail,
        state: EActionState.Fail,
    });
    return {
        getDetailStart: getDetailStart,
        getDetailStartNoThrottle: getDetailStartNoThrottle,
        getDetailSucceeded: getDetailSucceeded,
        getDetailFailed: getDetailFailed,
    };
};
var generateGetPaged = function (params) {
    var entity = params.entity;
    var getPagedStart = createAction({
        entity: entity,
        generic: EActionGeneric.Paged,
        state: EActionState.Start,
        sanitizePayload: function (payload) { return (__assign(__assign({}, payload), { query: sanitizeQuery({ query: payload.query }) })); },
    });
    var getPagedStartNoThrottle = createAction({
        entity: entity,
        generic: EActionGeneric.Paged,
        state: EActionState.StartNoThrottle,
        sanitizePayload: function (payload) { return (__assign(__assign({}, payload), { query: sanitizeQuery({ query: payload.query }) })); },
    });
    var getPagedSucceeded = createAction({
        entity: entity,
        generic: EActionGeneric.Paged,
        state: EActionState.Success,
    });
    var getPagedFailed = createAction({
        entity: entity,
        generic: EActionGeneric.Paged,
        state: EActionState.Fail,
    });
    return {
        getPagedStart: getPagedStart,
        getPagedStartNoThrottle: getPagedStartNoThrottle,
        getPagedSucceeded: getPagedSucceeded,
        getPagedFailed: getPagedFailed,
    };
};
var generateGetInfiniteLoad = function (params) {
    var entity = params.entity;
    var getInfiniteLoadStart = createAction({
        entity: entity,
        generic: EActionGeneric.InfiniteLoad,
        state: EActionState.Start,
        sanitizePayload: function (payload) { return (__assign(__assign({}, payload), { query: sanitizeQuery({ query: payload.query }) })); },
    });
    var getInfiniteLoadStartNoThrottle = createAction({
        entity: entity,
        generic: EActionGeneric.InfiniteLoad,
        state: EActionState.StartNoThrottle,
        sanitizePayload: function (payload) { return (__assign(__assign({}, payload), { query: sanitizeQuery({ query: payload.query }) })); },
    });
    var getInfiniteLoadForPageStart = createAction({
        entity: entity,
        generic: EActionGeneric.InfiniteLoad,
        state: EActionState.StartForPage,
        sanitizePayload: function (payload) { return (__assign(__assign({}, payload), { query: sanitizeQuery({ query: payload.query }) })); },
    });
    var getInfiniteLoadSucceeded = createAction({
        entity: entity,
        generic: EActionGeneric.InfiniteLoad,
        state: EActionState.Success,
    });
    var getInfiniteLoadFailed = createAction({
        entity: entity,
        generic: EActionGeneric.InfiniteLoad,
        state: EActionState.Fail,
    });
    return {
        getInfiniteLoadStart: getInfiniteLoadStart,
        getInfiniteLoadStartNoThrottle: getInfiniteLoadStartNoThrottle,
        getInfiniteLoadForPageStart: getInfiniteLoadForPageStart,
        getInfiniteLoadSucceeded: getInfiniteLoadSucceeded,
        getInfiniteLoadFailed: getInfiniteLoadFailed,
    };
};
var generateGetAllWhere = function (params) {
    var entity = params.entity;
    var getAllWhereStart = createAction({
        entity: entity,
        generic: EActionGeneric.GetAll,
        state: EActionState.Start,
        sanitizePayload: function (payload) { return (__assign(__assign({}, payload), { query: sanitizeQuery({ query: payload.query }) })); },
    });
    var getAllWhereStartNoThrottle = createAction({
        entity: entity,
        generic: EActionGeneric.GetAll,
        state: EActionState.StartNoThrottle,
        sanitizePayload: function (payload) { return (__assign(__assign({}, payload), { query: sanitizeQuery({ query: payload.query }) })); },
    });
    var getAllWhereSucceeded = createAction({
        entity: entity,
        generic: EActionGeneric.GetAll,
        state: EActionState.Success,
    });
    var getAllWhereFailed = createAction({
        entity: entity,
        generic: EActionGeneric.GetAll,
        state: EActionState.Fail,
    });
    return {
        getAllWhereStart: getAllWhereStart,
        getAllWhereStartNoThrottle: getAllWhereStartNoThrottle,
        getAllWhereSucceeded: getAllWhereSucceeded,
        getAllWhereFailed: getAllWhereFailed,
    };
};
var generateGetCount = function (params) {
    var entity = params.entity;
    var getCountStart = createAction({
        entity: entity,
        generic: EActionGeneric.Count,
        state: EActionState.Start,
        sanitizePayload: function (payload) { return (__assign(__assign({}, payload), { query: sanitizeQuery({ query: payload.query }) })); },
    });
    var getCountStartNoThrottle = createAction({
        entity: entity,
        generic: EActionGeneric.Count,
        state: EActionState.StartNoThrottle,
        sanitizePayload: function (payload) { return (__assign(__assign({}, payload), { query: sanitizeQuery({ query: payload.query }) })); },
    });
    var getCountSucceeded = createAction({
        entity: entity,
        generic: EActionGeneric.Count,
        state: EActionState.Success,
    });
    var getCountFailed = createAction({
        entity: entity,
        generic: EActionGeneric.Count,
        state: EActionState.Fail,
    });
    return {
        getCountStart: getCountStart,
        getCountStartNoThrottle: getCountStartNoThrottle,
        getCountSucceeded: getCountSucceeded,
        getCountFailed: getCountFailed,
    };
};
var generateCreate = function (params) {
    var entity = params.entity;
    var createStart = createAction({
        entity: entity,
        generic: EActionGeneric.Create,
        state: EActionState.Start,
    });
    var createSucceeded = createAction({
        entity: entity,
        generic: EActionGeneric.Create,
        state: EActionState.Success,
    });
    var createFailed = createAction({
        entity: entity,
        generic: EActionGeneric.Create,
        state: EActionState.Fail,
    });
    return {
        createStart: createStart,
        createSucceeded: createSucceeded,
        createFailed: createFailed,
    };
};
var generateUpdate = function (params) {
    var entity = params.entity;
    var updateStart = createAction({
        entity: entity,
        generic: EActionGeneric.Update,
        state: EActionState.Start,
    });
    var updateSucceeded = createAction({
        entity: entity,
        generic: EActionGeneric.Update,
        state: EActionState.Success,
    });
    var updateFailed = createAction({
        entity: entity,
        generic: EActionGeneric.Update,
        state: EActionState.Fail,
    });
    return {
        updateStart: updateStart,
        updateSucceeded: updateSucceeded,
        updateFailed: updateFailed,
    };
};
var generateUpdateMembers = function (params) {
    var entity = params.entity;
    var updateMembersStart = createAction({
        entity: entity,
        generic: EActionGeneric.UpdateMembers,
        state: EActionState.Start,
    });
    var updateMembersSucceeded = createAction({
        entity: entity,
        generic: EActionGeneric.UpdateMembers,
        state: EActionState.Success,
    });
    var updateMembersFailed = createAction({
        entity: entity,
        generic: EActionGeneric.UpdateMembers,
        state: EActionState.Fail,
    });
    return {
        updateMembersStart: updateMembersStart,
        updateMembersSucceeded: updateMembersSucceeded,
        updateMembersFailed: updateMembersFailed,
    };
};
var generateUpdateEmployees = function (params) {
    var entity = params.entity;
    var updateEmployeesStart = createAction({
        entity: entity,
        generic: EActionGeneric.UpdateEmployees,
        state: EActionState.Start,
    });
    var updateEmployeesSucceeded = createAction({
        entity: entity,
        generic: EActionGeneric.UpdateEmployees,
        state: EActionState.Success,
    });
    var updateEmployeesFailed = createAction({
        entity: entity,
        generic: EActionGeneric.UpdateEmployees,
        state: EActionState.Fail,
    });
    return {
        updateEmployeesStart: updateEmployeesStart,
        updateEmployeesSucceeded: updateEmployeesSucceeded,
        updateEmployeesFailed: updateEmployeesFailed,
    };
};
var generateRemove = function (params) {
    var entity = params.entity;
    var removeStart = createAction({
        entity: entity,
        generic: EActionGeneric.Remove,
        state: EActionState.Start,
    });
    var removeSucceeded = createAction({
        entity: entity,
        generic: EActionGeneric.Remove,
        state: EActionState.Success,
    });
    var removeFailed = createAction({
        entity: entity,
        generic: EActionGeneric.Remove,
        state: EActionState.Fail,
    });
    return {
        removeStart: removeStart,
        removeSucceeded: removeSucceeded,
        removeFailed: removeFailed,
    };
};
var generateArchive = function (params) {
    var entity = params.entity;
    var archiveStart = createAction({
        entity: entity,
        generic: EActionGeneric.Archive,
        state: EActionState.Start,
    });
    var archiveSucceeded = createAction({
        entity: entity,
        generic: EActionGeneric.Archive,
        state: EActionState.Success,
    });
    var archiveFailed = createAction({
        entity: entity,
        generic: EActionGeneric.Archive,
        state: EActionState.Fail,
    });
    return {
        archiveStart: archiveStart,
        archiveSucceeded: archiveSucceeded,
        archiveFailed: archiveFailed,
    };
};
var generateUnarchive = function (params) {
    var entity = params.entity;
    var unarchiveStart = createAction({
        entity: entity,
        generic: EActionGeneric.Unarchive,
        state: EActionState.Start,
    });
    var unarchiveSucceeded = createAction({
        entity: entity,
        generic: EActionGeneric.Unarchive,
        state: EActionState.Success,
    });
    var unarchiveFailed = createAction({
        entity: entity,
        generic: EActionGeneric.Unarchive,
        state: EActionState.Fail,
    });
    return {
        unarchiveStart: unarchiveStart,
        unarchiveSucceeded: unarchiveSucceeded,
        unarchiveFailed: unarchiveFailed,
    };
};
export var generateActions = function (params) {
    var getDetail = generateGetDetail(params);
    var getPaged = generateGetPaged(params);
    var getInfiniteLoad = generateGetInfiniteLoad(params);
    var getAllWhere = generateGetAllWhere(params);
    var getCount = generateGetCount(params);
    var create = generateCreate(params);
    var update = generateUpdate(params);
    var updateMembers = generateUpdateMembers(params);
    var updateEmployees = generateUpdateEmployees(params);
    var remove = generateRemove(params);
    var archive = generateArchive(params);
    var unarchive = generateUnarchive(params);
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, getDetail), getPaged), getInfiniteLoad), getAllWhere), getCount), create), update), updateMembers), updateEmployees), remove), archive), unarchive);
};
