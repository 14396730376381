var alertTriggers = {};
export var registerAlertTrigger = function (trigger, 
// Pass an ID to register an alert trigger that can be overridden at a later point
// Useful for when a hook is triggered when dependencies are updated
id) {
    alertTriggers[id || "".concat(Math.random())] = trigger;
};
export var showAlert = function (alert) {
    Object.values(alertTriggers).forEach(function (alertTrigger) {
        alertTrigger(alert);
    });
};
