var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from "axios";
import { CONFIG } from "../../config/app.config";
import { EEnvironment } from "../../config/app.config.types";
import api from "../../utils/api/api.utils";
import { appendFilter } from "../../utils/api.utils";
export var signIn = function (data) { return api.post("/oauth/login", { data: data, authenticated: false }); };
export var signInAutomatically = function (token) {
    return api.post("/oauth/token-login", {
        data: { token: token },
        authenticated: false,
    });
};
export var requestToken = function (userId, accountId) {
    return api.post("/system/user-token", {
        data: { userId: userId, accountId: accountId },
    });
};
export var refreshAccessToken = function (refreshToken) {
    return axios.post("".concat(CONFIG.BASE_URL, "/oauth/refresh"), {
        refreshToken: refreshToken,
    });
};
export var verifyPhone = function (userId, code) {
    return api.post("/oauth/confirm-phone?uid=".concat(userId, "&token=").concat(code));
};
export var adminVerifyEmail = function (userId) {
    return api.post("/oauth/admin-confirm-email?uid=".concat(userId));
};
export var adminVerifyPhone = function (userId) {
    return api.post("/oauth/admin-confirm-phone?uid=".concat(userId));
};
export var signUp = function (data) { return api.post("/users", { data: data, authenticated: false }); };
export var resendVerificationEmail = function (email) {
    return api.get("/users/email/verification/".concat(email));
};
export var getUser = function () {
    return api.get(appendFilter("/users/me", { include: ["tac", "broker"] }));
};
export var logoutUser = function () { return api.post("users/logout"); };
export var getTac = function (locale, platform, brokerId) { return __awaiter(void 0, void 0, void 0, function () {
    var rentioBrokerId;
    return __generator(this, function (_a) {
        rentioBrokerId = CONFIG.ENVIRONMENT === EEnvironment.Dev ? 1000 : 1;
        return [2 /*return*/, api.get("/brokers/".concat(brokerId || rentioBrokerId, "/tac/").concat(platform, "/").concat(locale))];
    });
}); };
export var getTacById = function (tacId) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    return [2 /*return*/, api.get("/tac/".concat(tacId))];
}); }); };
export var requestPasswordReset = function (data) {
    return api.post("/users/requestPasswordReset", {
        data: data,
    });
};
export var resetPassword = function (token, newPassword) {
    return api.post("/users/reset-password?access_token=".concat(token), {
        data: { newPassword: newPassword },
    });
};
export var updateUser = function (data) {
    return api.put("/users/me", { data: data });
};
export var updateUserById = function (id, data) {
    return api.put("/users/".concat(id), { data: data });
};
export var acceptTac = function (tacId) {
    return api.post("/users/me/tac/".concat(tacId, "/accept"));
};
