var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { getLocalizedText } from "../../../utils/i18n/i18n.utils";
import PropertyListItem from "../../components/PropertyListItem";
import ButtonPrimary from "../ButtonPrimary";
import FormInputBase from "../FormInputBase";
import { PropertyFetchListItem } from "../PropertyFetchListItem";
import Spacer, { ESpacerWeight } from "../Spacer";
import * as S from "./FormPropertySelectInline.styles";
var FormPropertySelectInline = /** @class */ (function (_super) {
    __extends(FormPropertySelectInline, _super);
    function FormPropertySelectInline() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.renderProperty = function (property) { return (_jsx(PropertyListItem, { property: property, selected: true, onPress: _this.handlePress(property.id) })); };
        // Event handlers
        _this.handlePress = function (propertyId) { return function () {
            _this.props.onDeselectProperty(propertyId);
        }; };
        return _this;
    }
    // Render
    FormPropertySelectInline.prototype.render = function () {
        var _this = this;
        var _a = this.props, title = _a.title, selectPropertyFromListCtaTitle = _a.selectPropertyFromListCtaTitle, error = _a.error, optional = _a.optional, extraInfoUrl = _a.extraInfoUrl, onPressExtraInfo = _a.onPressExtraInfo, info = _a.info, selectedPropertyId = _a.selectedPropertyId, onSelectMoreProperties = _a.onSelectMoreProperties;
        return (_jsxs(FormInputBase, { title: title, info: info, error: error, optional: optional, extraInfoUrl: extraInfoUrl, onPressExtraInfo: onPressExtraInfo, extraButtonTitle: selectedPropertyId ? selectPropertyFromListCtaTitle : undefined, onPressExtraButton: onSelectMoreProperties, children: [_jsx(Spacer, { weight: ESpacerWeight.W08 }), (function () {
                    if (selectedPropertyId) {
                        return (_jsx(PropertyFetchListItem, { shouldRefetch: false, propertyId: selectedPropertyId, renderProperty: _this.renderProperty }));
                    }
                    return (_jsx(S.EmptyButtonWrap, { children: _jsx(ButtonPrimary, { onPress: onSelectMoreProperties, title: getLocalizedText("choose.property"), isInverted: true }) }));
                })()] }));
    };
    return FormPropertySelectInline;
}(React.PureComponent));
// eslint-disable-next-line import/no-default-export
export default FormPropertySelectInline;
