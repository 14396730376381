import { compact } from "lodash";
import { getQueryHash } from "../utils/hash.utils";
import { generateUtils } from "./generator.utils";
export var generateSelectors = function (params) {
    var reducerKey = params.reducerKey;
    var utils = generateUtils(params);
    var getEntityState = function (state) {
        var mappedState = state;
        return mappedState[reducerKey];
    };
    var getDetailBase = function (state, id, customPath) {
        var _a;
        var key = utils.getDetailKey({ id: id, customPath: customPath });
        if (!key) {
            return undefined;
        }
        return (_a = getEntityState(state)) === null || _a === void 0 ? void 0 : _a.detail[key];
    };
    var getDetail = function (state, id, customPath) {
        var _a;
        return (_a = getDetailBase(state, id, customPath)) === null || _a === void 0 ? void 0 : _a.entity;
    };
    var isFetchingDetail = function (state, id, customPath) {
        var _a;
        return ((_a = getDetailBase(state, id, customPath)) === null || _a === void 0 ? void 0 : _a.pending) || false;
    };
    var fetchDetailError = function (state, id, customPath) {
        var _a;
        return (_a = getDetailBase(state, id, customPath)) === null || _a === void 0 ? void 0 : _a.error;
    };
    var getPaged = function (state, query, customPath) {
        var _a, _b, _c;
        if (!query) {
            return;
        }
        var hash = getQueryHash(query, customPath);
        var pagedState = (_a = getEntityState(state)) === null || _a === void 0 ? void 0 : _a.paged[hash];
        if (!pagedState) {
            return;
        }
        var pagedStateForPage = (_b = pagedState === null || pagedState === void 0 ? void 0 : pagedState.pages) === null || _b === void 0 ? void 0 : _b[query.page];
        var _d = pagedStateForPage || {}, pending = _d.pending, error = _d.error, data = _d.data;
        var items = (_c = data === null || data === void 0 ? void 0 : data.ids) === null || _c === void 0 ? void 0 : _c.map(function (id) { var _a, _b; return (_b = (_a = getEntityState(state)) === null || _a === void 0 ? void 0 : _a.detail["".concat(id)]) === null || _b === void 0 ? void 0 : _b.entity; });
        return {
            totalItems: pagedState.totalItems,
            totalPages: pagedState.totalPages,
            isFetching: pending,
            fetchError: error,
            items: items ? compact(items) : undefined,
        };
    };
    var getInfiniteLoad = function (state, query, customPath) {
        var _a;
        if (!query && !customPath) {
            return;
        }
        var hash = getQueryHash(query, customPath);
        var infiniteLoadState = (_a = getEntityState(state)) === null || _a === void 0 ? void 0 : _a.infiniteLoad[hash];
        if (!infiniteLoadState) {
            return;
        }
        var ids = [];
        var isFetching = false;
        var fetchError = undefined;
        var isExhausted = false;
        for (var page = 1; page <= (infiniteLoadState.totalPages || 1); page++) {
            var pageState = infiniteLoadState.pages[page];
            if (!pageState) {
                break;
            }
            if (pageState.ids) {
                ids.push.apply(ids, pageState.ids);
            }
            if (pageState.pending) {
                isFetching = true;
                break;
            }
            if (pageState.error) {
                fetchError = pageState.error;
                break;
            }
            if (page === infiniteLoadState.totalPages) {
                isExhausted = true;
            }
        }
        var items = ids === null || ids === void 0 ? void 0 : ids.map(function (id) { var _a, _b; return (_b = (_a = getEntityState(state)) === null || _a === void 0 ? void 0 : _a.detail["".concat(id)]) === null || _b === void 0 ? void 0 : _b.entity; });
        return {
            totalItems: infiniteLoadState.totalItems,
            totalPages: infiniteLoadState.totalPages,
            isFetching: isFetching,
            isExhausted: isExhausted,
            fetchError: fetchError,
            items: items ? compact(items) : undefined,
        };
    };
    // Use only in sage
    var getInfiniteLoadRaw = function (state, query, customPath) {
        var _a;
        if (!query && !customPath) {
            return;
        }
        var hash = getQueryHash(query, customPath);
        return (_a = getEntityState(state)) === null || _a === void 0 ? void 0 : _a.infiniteLoad[hash];
    };
    var getAllWhere = function (state, query, customPath) {
        var _a;
        if (!query && !customPath) {
            return;
        }
        var hash = getQueryHash(query, customPath);
        var getAllState = (_a = getEntityState(state)) === null || _a === void 0 ? void 0 : _a.allWhere[hash];
        var _b = getAllState || {}, pending = _b.pending, error = _b.error, ids = _b.ids;
        var items = ids === null || ids === void 0 ? void 0 : ids.map(function (id) { var _a, _b; return (_b = (_a = getEntityState(state)) === null || _a === void 0 ? void 0 : _a.detail["".concat(id)]) === null || _b === void 0 ? void 0 : _b.entity; });
        return {
            isFetching: pending,
            fetchError: error,
            items: items ? compact(items) : undefined,
        };
    };
    var isFetchingAllWhere = function (state, query, customPath) {
        var _a;
        if (!query && !customPath) {
            return false;
        }
        var hash = getQueryHash(query, customPath);
        var entityState = getEntityState(state);
        return ((_a = entityState === null || entityState === void 0 ? void 0 : entityState.allWhere[hash]) === null || _a === void 0 ? void 0 : _a.pending) || false;
    };
    var getCountBase = function (state, query, customPath) {
        var _a;
        if (!query) {
            return;
        }
        var key = utils.getCountKey({ query: query, customPath: customPath });
        return (_a = getEntityState(state)) === null || _a === void 0 ? void 0 : _a.count[key];
    };
    var getCount = function (state, query, customPath) {
        var _a;
        return (_a = getCountBase(state, query, customPath)) === null || _a === void 0 ? void 0 : _a.count;
    };
    var isFetchingCount = function (state, query, customPath) {
        var _a;
        return ((_a = getCountBase(state, query, customPath)) === null || _a === void 0 ? void 0 : _a.pending) || false;
    };
    var fetchCountError = function (state, query, customPath) {
        var _a;
        return (_a = getCountBase(state, query, customPath)) === null || _a === void 0 ? void 0 : _a.error;
    };
    var getCreatedDetail = function (state) { var _a, _b; return (_b = getDetailBase(state, (_a = getEntityState(state)) === null || _a === void 0 ? void 0 : _a.create.id)) === null || _b === void 0 ? void 0 : _b.entity; };
    var isCreating = function (state) { var _a; return ((_a = getEntityState(state)) === null || _a === void 0 ? void 0 : _a.create.pending) || false; };
    var createError = function (state) { var _a; return (_a = getEntityState(state)) === null || _a === void 0 ? void 0 : _a.create.error; };
    var isUpdating = function (state, id) { var _a, _b; return ((_b = (_a = getEntityState(state)) === null || _a === void 0 ? void 0 : _a.update["".concat(id)]) === null || _b === void 0 ? void 0 : _b.pending) || false; };
    var updateError = function (state, id) { var _a, _b; return (_b = (_a = getEntityState(state)) === null || _a === void 0 ? void 0 : _a.update["".concat(id)]) === null || _b === void 0 ? void 0 : _b.error; };
    var isRemoving = function (state, id) { var _a, _b; return ((_b = (_a = getEntityState(state)) === null || _a === void 0 ? void 0 : _a.remove["".concat(id)]) === null || _b === void 0 ? void 0 : _b.pending) || false; };
    var removeError = function (state, id) { var _a, _b; return (_b = (_a = getEntityState(state)) === null || _a === void 0 ? void 0 : _a.remove["".concat(id)]) === null || _b === void 0 ? void 0 : _b.error; };
    var isArchiving = function (state, id) { var _a, _b; return ((_b = (_a = getEntityState(state)) === null || _a === void 0 ? void 0 : _a.archive["".concat(id)]) === null || _b === void 0 ? void 0 : _b.pending) || false; };
    var archivingError = function (state, id) { var _a, _b; return (_b = (_a = getEntityState(state)) === null || _a === void 0 ? void 0 : _a.archive["".concat(id)]) === null || _b === void 0 ? void 0 : _b.error; };
    var getAllRaw = function (state) {
        var _a;
        return compact(Object.values(((_a = getEntityState(state)) === null || _a === void 0 ? void 0 : _a.detail) || {}).map(function (x) { return x === null || x === void 0 ? void 0 : x.entity; }));
    };
    return {
        getDetail: getDetail,
        isFetchingDetail: isFetchingDetail,
        fetchDetailError: fetchDetailError,
        getPaged: getPaged,
        getInfiniteLoad: getInfiniteLoad,
        getInfiniteLoadRaw: getInfiniteLoadRaw,
        getAllWhere: getAllWhere,
        isFetchingAllWhere: isFetchingAllWhere,
        getCount: getCount,
        isFetchingCount: isFetchingCount,
        fetchCountError: fetchCountError,
        getCreatedDetail: getCreatedDetail,
        isCreating: isCreating,
        createError: createError,
        isUpdating: isUpdating,
        updateError: updateError,
        isRemoving: isRemoving,
        removeError: removeError,
        isArchiving: isArchiving,
        archivingError: archivingError,
        getAllRaw: getAllRaw,
    };
};
